
// Bootstrap

// $body-bg:                   $di-ai-color-lightergray;

$font-family-sans-serif: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$link-color: $di-ai-color-black;
$link-hover-color: inherit;
// $link-hover-color:                        lighten($link-color, 20%);
$link-hover-decoration:                   none;

$spacer: 32px;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.25),
    5: ($spacer * 1.5),
    6: ($spacer * 2),
    24: ($spacer * .75) // 24px
  ),
  $spacers
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$container-max-widths: (
  sm: 480px,
  md: 640px,
  lg: 800px,
  xl: 1040px
) !default;

$grid-columns:                12 !default;
$grid-gutter-width:           16px !default;
$grid-row-columns:            6 !default;


// stylelint-disable value-keyword-case
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base:              .875rem; // Assumes the browser default, typically `16px`
$font-size-lg:                1rem;
$font-size-sm:                .750rem;

$lead-font-size:              1rem;
$lead-font-weight:            400;

// $font-weight-lighter:         lighter !default;
// $font-weight-light:           300 !default;
// $font-weight-normal:          400 !default;
// $font-weight-bold:            700 !default;
// $font-weight-bolder:          bolder !default;

// $font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.15;

$h1-font-size:                40px;
$h2-font-size:                32px;
$h3-font-size:                24px;
$h4-font-size:                16px;
$h5-font-size:                14px; //$font-size-base * 1.25 !default;
// $h6-font-size:                $font-size-base !default;



$primary:       $di-ai-color-blue;
$secondary:     $di-ai-color-gray;
$success:       $di-ai-color-green;
// $info:          $cyan !default;
// $warning:       $yellow !default;
$danger:        $di-ai-color-red;
$light:         $di-ai-color-white;
$dark:          $di-ai-color-black;


$border-radius:               3px;
$border-radius-lg:            4px;
$border-radius-sm:            2px;

$card-border-color:                 $di-ai-color-lightgray;
$card-border-radius:                6px;

$box-shadow: 0px 10px 20px rgba(31, 43, 57, 0.08), 0px 2px 6px rgba(31, 43, 57, 0.08), 0px 0px 1px rgba(31, 43, 57, 0.08);

$input-height: 3rem;

$btn-font-weight: 600;  

$input-btn-padding-x: 1rem;
$input-btn-padding-y: .25rem;

$input-btn-padding-x-lg: 1rem;
$input-btn-padding-y-lg: .25rem;

//$input-btn-padding-y-lg:      .5rem !default;
//$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      14px;
//$input-btn-line-height-lg:    $line-height-lg !default;


$input-border-color: rgba($di-ai-color-black, 20%);
// $input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              $di-ai-color-green;
// $input-focus-color:                     $input-color !default;
// $input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:               none;

$headings-font-weight:        600;
$headings-line-height:        1.25;
// $headings-margin-bottom:      $spacer / 2



$card-spacer-y:                     1.5rem;



// Modals

// // Padding applied to the modal body
$modal-inner-padding:               1.5rem;

// // Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
// $modal-footer-margin-between:       .5rem !default;

// $modal-dialog-margin:               .5rem !default;
// $modal-dialog-margin-y-sm-up:       1.75rem !default;

// $modal-title-line-height:           $line-height-base !default;

// $modal-content-color:               null !default;
// $modal-content-bg:                  $white !default;
// $modal-content-border-color:        rgba($black, .2) !default;
$modal-content-border-width:        0;
// $modal-content-border-radius:       $border-radius-lg !default;
// $modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
// $modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) !default;
// $modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) !default;

$modal-backdrop-bg:                 $di-ai-color-black;
$modal-backdrop-opacity:            .5; // .35;
// $modal-header-border-color:         $border-color !default;
// $modal-footer-border-color:         $modal-header-border-color !default;
$modal-header-border-width:         0;
$modal-footer-border-width:         0;
$modal-header-padding-y:            22px;
$modal-header-padding-x:            1.5rem;
// $modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

// $modal-xl:                          1140px !default;
// $modal-lg:                          800px !default;
$modal-md:                          512px;
$modal-sm:                          336px;

// $modal-fade-transform:              translate(0, -50px) !default;
// $modal-show-transform:              none !default;
// $modal-transition:                  transform .3s ease-out !default;
// $modal-scale-transform:             scale(1.02) !default;

$progress-bar-transition:           width 1s ease !default;


$list-group-border-color:           $di-ai-color-gray;

$list-group-disabled-color:         $di-ai-color-black;
//$list-group-disabled-bg:            $list-group-bg !default;

// $tooltip-font-size:                 $font-size-sm !default;
$tooltip-max-width:                 100%;
// $tooltip-color:                     $white !default;
$tooltip-bg:                        $di-ai-color-black;
$tooltip-border-radius:             .25rem;
$tooltip-opacity:                    1;
$tooltip-padding-y:                 .5rem;
$tooltip-padding-x:                 .5rem;
$tooltip-arrow-width:               .5rem;
$tooltip-arrow-height:              .25rem;
//$tooltip-margin:                    -$tooltip-arrow-height;

$input-btn-focus-box-shadow: none;
$input-btn-focus-width:       0;

// fix unmatched pseudo-class :lang
$custom-file-text: (
 // en: "Browse"
);

// Custom

$di-ai-navbar-height: 80px;