.btn {

  min-height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.btn-google {
    color: $di-ai-color-blue;
    border-color: $di-ai-color-blue;
    background-color: $di-ai-color-white;
    padding-left: 48px;
    padding-right: 48px;

    &:hover,
    &:focus {
      background-color: rgba($di-ai-color-blue, 0.1);
    }

    &:after {
      background-image: di-ai-get-icon('google');
      left: 16px;
    }

  }

  &.btn-account {
    color: $di-ai-color-blue;
    border-color: rgba($di-ai-color-blue, 0.25);
    background-color: $di-ai-color-white;
    padding-left: 48px;
    border-width: 2px;
    
    &:after {
      background-image: di-ai-get-icon('user', $di-ai-color-blue);
      left: 16px;
    }

    &:hover,
    &:focus {
      border-color: $di-ai-color-blue;
    }

  }

  &.btn-blue {
    color: $di-ai-color-white;
    background-color: $di-ai-color-blue;

    &:hover,
    &:focus {
      background-image: linear-gradient(0deg, rgba(white, 0.2), rgba(white, 0.2));
    }

    &:active {
      background-image: linear-gradient(0deg, rgba(black, 0.1), rgba(black, 0.1));
    }
  }

  &.btn-green {
    color: $di-ai-color-white;
    background-color: $di-ai-color-green;

    &:hover,
    &:focus {
      background-image: linear-gradient(0deg, rgba(white, 0.2), rgba(white, 0.2));
    }

    &:active {
      background-image: linear-gradient(0deg, rgba(black, 0.1), rgba(black, 0.1));
    }

    &:disabled {
      color: rgba($di-ai-color-black, 0.35);
      background-color: $di-ai-color-lightgray;
      background-image: none;
    }
  }

  &.btn-lightgreen {
    color: $di-ai-color-darkgreen;
    background-color: rgba($di-ai-color-green, 0.2);

    &:hover,
    &:focus {
      color: $di-ai-color-white;
      background-color: $di-ai-color-green;
    }

    &:active {
      color: $di-ai-color-white;
      background-color: $di-ai-color-green;
      background-image: linear-gradient(0deg, rgba(black, 0.1), rgba(black, 0.1));
    }
  }

  &.btn-outlineblue {
    color: $di-ai-color-blue;
    background-color: $di-ai-color-white;
    border-color: $di-ai-color-blue;

    &:hover,
    &:focus {
      color: $di-ai-color-white;
      background-color: $di-ai-color-blue;
      border-color: transparent;
    }

    &:active {
      color: $di-ai-color-white;
      background-color: $di-ai-color-blue;
      background-image: linear-gradient(0deg, rgba(black, 0.1), rgba(black, 0.1));
      border-color: transparent;
    }
  }

  &.btn-outlinegray {
    color: $di-ai-color-black;
    border-color: rgba(31, 43, 57, 0.25);
    padding-left: 52px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 16px;
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;
      border: 2px solid rgba(31, 43, 57, 0.25);
      border-radius: 50%;
      transition: border-color 0.15s ease-in-out;
    }

    &:hover {
      // color: $di-ai-color-black;
      border-color: rgba(31, 43, 57, 0.5);
      // border-color: transparent;
      &:after {
        position: absolute;
        left: 16px;
        top: calc(50% - 10px);
        width: 20px;
        height: 20px;
        border: 2px solid rgba(31, 43, 57, 0.5);
        border-radius: 50%;
      }
    }

    &:active {
      // color: $di-ai-color-white;
      // background-color: $di-ai-color-lightgray;
      // background-image: linear-gradient(0deg, rgba(black, 0.1), rgba(black, 0.1));
      // border-color: transparent;
    }
  }

  &.btn-outlinegreen {
    color: $di-ai-color-black;
    background-color: rgba($di-ai-color-darkgreen, 0.025);
    border-color: $di-ai-color-darkgreen;
    padding-left: 52px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 16px;
      top: calc(50% - 10px);
      width: 20px;
      height: 20px;
      border: 6px solid $di-ai-color-darkgreen;
      border-radius: 50%;
      transition: border-color 0.15s ease-in-out;
    }

    &:hover{
      // color: $di-ai-color-black;
      background-color: rgba($di-ai-color-darkgreen, 0.1);
      // border-color: transparent;
    }

    &:active {

    }
  }

  &.btn-outlineblue {
    color: $di-ai-color-blue;
    background-color: $di-ai-color-white;
    border-color: $di-ai-color-blue;

    &:hover,
    &:focus {
      color: $di-ai-color-white;
      background-color: $di-ai-color-blue;
      border-color: transparent;
    }

    &:active {
      color: $di-ai-color-white;
      background-color: $di-ai-color-blue;
      background-image: linear-gradient(0deg, rgba(black, 0.1), rgba(black, 0.1));
      border-color: transparent;
    }
  }

  &.btn-outlineblueblack {
    color: $di-ai-color-black;
    background-color: $di-ai-color-white;
    border-color: $di-ai-color-blue;

    &:hover,
    &:focus {
      color: $di-ai-color-white;
      background-color: $di-ai-color-blue;
      border-color: transparent;
    }

    &:active {
      color: $di-ai-color-white;
      background-color: $di-ai-color-blue;
      background-image: linear-gradient(0deg, rgba(black, 0.1), rgba(black, 0.1));
      border-color: transparent;
    }
  }

  &.btn-go-top {
    color: $di-ai-color-white;
    background-color: $di-ai-color-black;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    position: absolute;
    bottom: 24px;
    right: 24px;
    z-index: 999;

    background-image: di-ai-get-icon('arrow-up');
    background-position: center;
    background-repeat: no-repeat;
  
    &:hover {
      background-image: di-ai-get-icon('arrow-up'), linear-gradient(0deg, rgba(white, 0.2), rgba(white, 0.2));
    }
  
    &:active {
      background-image: di-ai-get-icon('arrow-up'), linear-gradient(0deg, rgba(black, 0.1), rgba(black, 0.1));
    }
  }
}

span {
  &.btn-back {
    cursor: pointer;
    padding-left: 24px;
    &:after {
      background-image: di-ai-get-icon('arrow-back', $di-ai-color-black);
      width: 16px;
      height: 16px;
    }
  }

  &.btn-plus {
    cursor: pointer;
    font-weight: 600;
    padding-right: 34px;
    &:after {
      background-image: di-ai-get-icon('plus-blue');
      width: 28px;
      height: 28px;
    }
    &:hover,
    &:focus {
      &:after {
        background-image: linear-gradient(0deg, rgba(white, 0.2), rgba(white, 0.2)), di-ai-get-icon('plus-blue');
      }
    }
  }
}

