// Grayscale

$di-ai-color-black:       #1F2B39;
$di-ai-color-lightgray:   #DDDFE1;
$di-ai-color-lightergray: #F4F4F5;
$di-ai-color-lightestgray:#EFF0F1;
$di-ai-color-gray:        #C7CACD;
$di-ai-color-white:       #FFFFFF;

// Others

$di-ai-color-blue:        #2AA8EF;
$di-ai-color-green:       #6DCC41;
$di-ai-color-darkgreen:   #3CBB00;
$di-ai-color-red:         #FF522C;
$di-ai-color-violet:      #C826F0;
$di-ai-color-yellow:      #FFCC16;
$di-ai-color-orange:      #FF9900;

$di-ai-colors: (
  'black': $di-ai-color-black,
  'lightgray': $di-ai-color-lightgray,
  'lightergray': $di-ai-color-lightergray,
  'lightestgray': $di-ai-color-lightestgray,
  'gray': $di-ai-color-gray,
  'white': $di-ai-color-white,
  'blue': $di-ai-color-blue,
  'green': $di-ai-color-green,
  'darkgreen': $di-ai-color-darkgreen,
  'red': $di-ai-color-red,
  'violet': $di-ai-color-violet,
  'yellow': $di-ai-color-yellow,
  'orange': $di-ai-color-orange
);


@each $key, $value in $di-ai-colors {
  .di-ai-color-#{$key} {
    color: $value !important;
  }
  .di-ai-bg-#{$key} {
    background-color: $value !important;
  }
}