.di-ai-leaflet {
    display: block;
    width: 100%;
    max-width: 400px;

    &-1 {
        background-image: url('../../images/png/AdobeStock_236268671.png');
        .icon {
            background-image: di-ai-get-icon('package');
        }
        .di-ai-leaflet-content {
            &:after {
                background-image: url('../../images/png/Macbook_1.png');
            }
        }
    }

    &-2 {
        background-image: url('../../images/png/AdobeStock_84349751.png');
        .icon {
            background-image: di-ai-get-icon('interpretation');
        }
        .di-ai-leaflet-content {
            &:after {
                background-image: url('../../images/png/Macbook_2.png');
            }
        }
    }

    .di-ai-leaflet-wrapper {
        position: relative;
        padding-top: 138.75%; // 555:300 Aspect Ratio
        border-radius: 8px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position-y: bottom;

        .di-ai-leaflet-content {
            border-radius: 8px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, $di-ai-color-lightestgray 30%, rgba(239, 240, 241, 0) 100%);
            color: $di-ai-color-black;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 32px;
                right: 32px;
                bottom: 40px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position-y: bottom;
            }

            a {
                position: relative;
                z-index: 1;
            }
            

            .icon {
                width: 40px;
                height: 40px;
            }
        }
    }
}