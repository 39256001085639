

// main-layout

.di-ai-wrapper {
    min-height: 100vh;
}

.di-ai-content-wrapper {
    margin-top: $di-ai-navbar-height;
    padding-top: 32px;
}

.di-ai-content-wrapper-alter {
    margin-top: $di-ai-navbar-height;
    height: calc(100vh - #{$di-ai-navbar-height});
    overflow: hidden;


    &--stepper {
        background-color: $di-ai-color-white;
        border-right: 1px solid rgba($di-ai-color-black, .1);
        flex: 0 0 248px;
        @include media-breakpoint-down(sm) {
            display: none;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 32px 24px 64px 24px;

        .step {
            flex: 0 0 40px;
            padding-left: 16px;
            display: flex;
            align-items: center;
            position: relative;
            border-radius: 4px;
            opacity: 0.5;
            &--counter {
                min-width: 16px;
            }
            &--label {
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 128px;
                font-weight: 600;
            }

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                right: 16px;
                width: 16px;
                height: 16px;
                transform: translateY(-50%);
                background-repeat: no-repeat;
                background-position: center;
                transition: background-image 0.15s ease-in-out;
            }

            &-current {
                opacity: unset;
                background-color: rgba($di-ai-color-blue, 0.1);
                &:after {
                    background-image: url('../../images/svg/Step_current.svg');
                }

            } 
            &-checked {
                opacity: unset;
                &:after {
                    background-image: url('../../images/svg/Step_checked.svg');
                }
            }
        }
    }
    &--content {
        flex: 1 0 0;
        position: relative;
        overflow: hidden;
    }
    &--footer {
        border-top: 1px solid rgba($di-ai-color-black, .1);
        background-color: $di-ai-color-white;
    }
}

.di-ai-container {
    @include media-breakpoint-up(sm) {
        padding: 0;
      }
}

// home
.di-ai-home-leaflet-left {
    display: flex;
    justify-content: center;
    width: 100%;
    @include media-breakpoint-up(md) {
        padding-right: 4px;
        justify-content: right;
      }
    @include media-breakpoint-up(lg) {
        padding-right: 8px;
    }
    @include media-breakpoint-up(xl) {
        padding-right: 16px;
    }
}

.di-ai-home-leaflet-right {
    display: flex;
    justify-content: center;
    width: 100%;
    @include media-breakpoint-up(md) {
        padding-left: 4px;
        justify-content: left;
    }
    @include media-breakpoint-up(lg) {
        padding-left: 8px;
    }
    @include media-breakpoint-up(xl) {
        padding-left: 16px;
    }
}


// lite-layout
.di-ai-wrapper-lite {
    min-height: 100vh;
    width: 100%;
    position: relative;
}

.di-ai-content-wrapper-lite {
    margin-bottom: 24px;
}


// app-user-info {
//     display: flex;
//     flex-grow: 1;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// }

.link-white {
    color: #ffffff;

    &:hover {
        color: #ffffff;
        opacity: .8;
    }
}

.link-blue {
    color: $di-ai-color-blue;

    &:hover {
        color: $di-ai-color-blue;
        opacity: .8;
    }
}

i.di-ai-icon-x {
    display: block;
    width: 24px;
    height: 24px;
    background-image: di-ai-get-icon('user', $di-ai-color-blue);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.with-icon {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
        transition: background-image 0.15s ease-in-out;
    }

    &-sm {
        &:after {
        width: 16px;
        height: 16px;
        }
    }

    &-lg {
        &:after {
        width: 32px;
        height: 32px;
        }
    }

    &-left {
        padding-left: 32px;
        &:after {
            left: 0;
        }
    }
    &-right {
        padding-right: 32px;
        &:after {
            right: 0;
        }
    }

    &-btn-left {
        padding-left: 48px;
        &:after {
            left: 16px;
        }
    }
    &-btn-right {
        padding-right: 48px;
        &:after {
            right: 16px;
        }
    }
}


.di-ai-btn-user {
    &:after {
        background-image: di-ai-get-icon('user', $di-ai-color-blue);
    }
    &:hover, &:active {
        &:after {
        background-image: di-ai-get-icon('user', $di-ai-color-white);
        }
    }
}

.card-body {
    padding: 24px;
}

label {
    font-size: 12px;
    font-weight: 600;
    line-height: 135%;
}

.form-group {
    &.required {
        label {
            &:after {
                content: ' *';
                color: #FF522C;
            }
        }
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.di-ai-input-wrapper {
    position:relative;
    display: block;

    &:after {
        position: absolute;
        top: 50%;
        right: $input-btn-padding-x;
        display: block;
        transform: translateY(-50%);
        content: var(--input-suffix, "");  
        opacity: var(--input-suffix-opacity, 1);          
    }
}

.form-control.ng-invalid {
    &.ng-dirty, &.ng-touched {
    border-color: $di-ai-color-red;
    }
}


.di-ai-invalid-feedback {
    width: 100%;
    margin-top: .5rem;
    font-size: 12px;
    color: $di-ai-color-red;
    text-align: right;
}


.btn-lg {
    font-weight: 700;
    min-height: 3rem;
}

.btn-success {
    color: $di-ai-color-white !important;
}

.di-ai-divider-or {
    display: flex;
    align-items: center;
    width: 100%;

    span {
        display: block;
        height: 48px;
        position: relative;
        flex-grow: 1;

        &:first-child {
            margin-right: 24px;
        }

        &:last-child {
            margin-left: 24px;
        }

        &:after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 50%;
            border-bottom: 1px solid;
            border-color: $di-ai-color-black;
            opacity: .15;
        }
    }
}

.di-ai-form-lg {
    width: 336px;
    max-width: 100%;
}

.di-ai-register-info {
    background-color: rgba($di-ai-color-blue, .2);
    border-radius: 4px;
    margin-bottom: 24px;
    padding: 16px;
    display: flex;

    span {
        flex-grow: 1;
    }

    a {
        font-weight: 600;
    }
}

.tests-container {
    position: relative;
    
    @include media-breakpoint-up(lg) {
        min-height: 250px;
        height: calc(100vh - 249px);
    }
}

.scroll-shadow {
    &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 40px;
        background-image: linear-gradient(to bottom, $di-ai-color-lightergray, transparent);
    }
}

.badania {
    overflow: hidden;
    .card-body {
        display: flex;
        flex-direction: column;

        .badania-lista {
            flex-grow: 1;
        }
    }
}

.di-ui-filter-row {
    display: flex;
    align-items: flex-end;

    .form-group {
        flex-grow: 1;
        flex-basis: 0;
    }
}

    *,
    *::before,
    *::after {
        letter-spacing: -0.03em;
    }


    .modal-body {
        padding-top: .25rem;
        padding-bottom: 0;
    }

    .font-weight-semibold {
        font-weight: 600 !important;
    }

    .di-ai-spinner {
        position: relative;
        img {
            width: 64px;
            height: auto;
        
            &:last-child {
                position: absolute;
                top:0;
                left:0;
                animation:spin 2s linear infinite;
            }
            &:first-child {
                position: relative;
                z-index: 100;
            }
        }
    }



    @keyframes spin { 
        100% { 
            transform:rotate(360deg); 
        } 
    }


    .di-ai-list-item {
        
        border-radius: 4px;
        min-height: 48px;
        display: flex;
        align-items: center;
        padding: .5rem 1rem;
        user-select: none;
        cursor: pointer;
        position: relative;
        margin-bottom: 8px;

        &+& {
            border-top-width: 1px !important;
        }

        &.disabled {
            opacity: 0.25;
            pointer-events: all !important;
        }

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 16px;
            width: 24px;
            height: 24px;
            transform: translateY(-50%);
            background-repeat: no-repeat;
            background-position: center;
            transition: opacity 0.15s ease-in-out;
            opacity: 0;
        }

        &:hover,
        &:focus {
            &:after {
                opacity: 1;
            }
        }


        &.di-ai-list-item-panel {
            transition: background-color 0.15s ease-in-out;
            &:hover,
            &:focus {
                background-color: rgba($di-ai-color-blue, .1);
                border-color: $di-ai-color-blue;
            }
            &:after {
                background-image: di-ai-get-icon('plus-blue-light');
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.di-ai-list-item-info {
            background-color: rgba($di-ai-color-blue, .1);
            border-color: $di-ai-color-blue;
            border-width: 1px;
            border-style: solid;
            padding-left: 40px;
            cursor: default;

            &:after {
                opacity: 1;
                right: calc(100% - 32px);
                background-image: di-ai-get-icon('info-b', $di-ai-color-blue);
            }
        }

        &.di-ai-list-item-result {
            transition: padding 0.15s ease-in-out;
            span {
                margin-left: auto;
                opacity: 0.6;    
            }

            div {
                flex-grow: 1;
                flex-basis: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            padding-right: 48px;
            &:after {
                background-image: di-ai-get-icon('close-decline', $di-ai-color-black);
                opacity: 1 !important;
                right: 12px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &.di-ai-list-item-cross {
            transition: padding 0.15s ease-in-out;
            span {
                margin-left: auto;
                opacity: 0.6; 
            }

            &.addable {
                padding-right: 52px;
                &:after {
                    opacity: 1;
                    right: 12px;
                    background-image: di-ai-get-icon('plus', $di-ai-color-black);
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.selected {
                padding-right: 48px;
                background-color: rgba($di-ai-color-green, .1);
                border-color: $di-ai-color-green;
                &:after {
                    opacity: 1;
                    right: 12px;
                    background-image: di-ai-get-icon('confirm');
                }
            }
        }
    }



    .di-ai-list-item-horizontal {
        
        border-radius: 3px;
        min-height: 32px;
        display: flex;
        align-items: center;
        padding: .25rem 2rem .25rem .75rem;
        user-select: none;
        cursor: pointer;
        position: relative;
        background-color: rgba($di-ai-color-blue, .25);
        color: $di-ai-color-blue;
        border: none;
        margin-right: 8px;
        margin-bottom: 8px;
        font-weight: 600;

        span {
            margin-left: 8px;
            font-weight: 400;
        }

        &:hover,
        &:focus {
            background-color: rgba($di-ai-color-blue, .3);
        }

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 12px;
            width: 12px;
            height: 12px;
            transform: translateY(-50%);
            background-repeat: no-repeat;
            background-position: center;
            background-image: di-ai-get-icon('close-decline', $di-ai-color-blue);
        }
    }


    .card-title {
        margin-bottom: 1rem;
    }

    .bg-warning {
        background-color: #FF881A !important;
    }

    .di-ai-results-list-container {
        flex-basis: 0;
        flex-grow: 1;
        // margin-right: -16px;
        
        .ng-scroll-content {
            // padding-right: 16px;
            max-width: 100%;
        }
    }


body {
    &.body-bg-gray {
        background-color: $di-ai-color-lightergray;
    }
    &.body-bg-pic-1 {
        &:before
        {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            height: 400px;
            max-height: 50vh;
            min-height: 80px;
            background-image: url('../../images/png/AdobeStock_1.png');
            background-position-x: center;
            background-position-y: bottom;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    &.body-bg-pic-2 {
        &:before
        {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            height: 400px;
            max-height: 50vh;
            min-height: 80px;
            background-image: url('../../images/png/AdobeStock_2.png');
            background-position-x: center;
            background-position-y: bottom;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    &.body-bg-pic-3 {
        &:before
        {
            background-color: $di-ai-color-lightergray;
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            height: 400px;
            max-height: 50vh;
            min-height: 80px;
            background-image: url('../../images/png/Left.png'), url('../../images/png/Right.png');
            background-position-x: left, right;
            background-position-y: bottom;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}   



.form-control {
    font-weight: 600;

    &:active {
        border-color: rgba($di-ai-color-black, .4);
    }

    &:hover:not(:focus){
        border-color: rgba($di-ai-color-black, .4);
      }
}

select.form-control {
    appearance: none;
    background-image: di-ai-get-icon('drop-arrow', $di-ai-color-black);
    background-repeat: no-repeat;
    background-position-x: calc(100% - 16px);
    background-position-y: 50%;
}


button.close {
    opacity: 1;
    &:after {
        background-image: di-ai-get-icon('decline-modal', $di-ai-color-black);
        top: 20px;
        right: 24px;
        transform: none;
    }
}


.modal-body {
    ng-scrollbar {
        height: calc(100vh - 260px);
        min-height: 150px;
    }
}

.divider-v-small {
    display: inline-block;
    height: 16px;
    width: 0;
    border-right: 1px solid;
    border-color: $di-ai-color-gray;
}

.di-ai-search-wrapper {
    padding: 0 !important;

    input {
        padding-left: 48px;
    }

    &:after {
        height: 16px;
        width: 16px;
        background-image: di-ai-get-icon('search', $di-ai-color-black);
    }
}

.card {
    .card-header-personal {
        padding-left: 56px;
        background-image: di-ai-get-icon('package', $di-ai-color-green);
        background-repeat: no-repeat;
    }
}

.di-ai-personal-rowbar {
    background-color: rgba($di-ai-color-black, .1);
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 600;
    text-transform: uppercase;
}

.di-ai-personal-footer {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    &>div { // cena
        flex-grow: 1;
        align-self: flex-end;
        order: 1;
        h3, h4 {
            margin-bottom: 0;
            display: inline-block;
            color: $di-ai-color-darkgreen;
        }
        h4 {
            margin-left: 4px;
            text-transform: uppercase;
        }
    }
    &>a { // szczegolowe informacje
        cursor: pointer;
        padding-right: 40px;
        &:after {
            right: 16px;
            background-image: di-ai-get-icon('arrow', $di-ai-color-black);
        }
        @include media-breakpoint-down(md) {
            margin-top: 16px;
            order: 5;
        }
        @include media-breakpoint-up(lg) {
            order: 2;
        }
    }
    // &>button.btn-blue { // umow wizyte
    //     order: 3;
    //     @include media-breakpoint-down(md) {
    //         margin-top: 16px;
    //         margin-bottom: 16px;
    //         width: 100%;
    //     }
    //     @include media-breakpoint-up(lg) {
    //         margin-left: 16px;
    //         margin-right: 8px;
    //     }
    // }
    &>button.btn-green { // zamow pakiet
        order: 3;
        @include media-breakpoint-down(md) {
            margin-top: 16px;
            margin-bottom: 16px;
            width: 100%;
        }
        @include media-breakpoint-up(lg) {
            margin-left: 16px;
            margin-right: 0px;
        }
    }
}

.di-ai-green-dot-item {
    padding-left: 24px;
    background-image: di-ai-get-icon('green-dot');
    background-repeat: no-repeat;
    font-size: 16px;
}

.di-ai-success {
    text-align: center;
    span {
        display: inline-block;
        margin-left: auto;
        background-color: $di-ai-color-green;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-image: di-ai-get-icon('check', $di-ai-color-white);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 32px;
    }
}

.di-ai-crossing-wrapper {
    min-height: 120px;
    position: relative;

    .di-ai-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .filter-blur {
        filter: blur(4px);
    }
}

.di-ai-rabat {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1000;
        background-image: di-ai-get-icon('vector', $di-ai-color-red);
        background-repeat: no-repeat;
    }
}

.text-darkgreen {
    color: $di-ai-color-darkgreen;
}

.link:hover,
.nav-link:hover {
    opacity: 0.8;
}

.di-ai-table {
    width: 100%;

    td {
        padding: 0 0 16px 0;
        vertical-align: top;

        input {
            margin-right: 8px;
        }
        &:nth-child(1) {
            padding-right: 8px;
        }
        &:nth-child(2) {
            opacity: 0.6;
            text-align: right;
            white-space: nowrap;
        }
        &:nth-child(3) {
            text-align: right;
        }
    }
}

.blue-link {
    cursor: pointer;
    color: $di-ai-color-blue !important;
    font-weight: 600;
}

.x-link {
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: 16px;
    background-image: di-ai-get-icon('close-decline', $di-ai-color-black);
    background-repeat: no-repeat;
    background-position: center;
}
// fix tooltip arrow position
ngb-tooltip-window.bs-tooltip-bottom .arrow,
ngb-tooltip-window.bs-tooltip-top .arrow {
    left: calc(50% - .25rem) !important;
}

app-crossing {
    label{
        font-weight: unset;
        line-height: unset;
        font-size: unset;
    }
}



.c-result-range--container {
    padding: 22px 0;
    position: relative;

    .c-result-range--range {

        position: relative;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        background-color: $di-ai-color-red;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 8%;
            right: 8%;
            background-color: $di-ai-color-darkgreen;
        }

        span {
            display: block;
            position: absolute;
            width: 2px;
            top:-4px;
            bottom:-4px;
            background-color: $di-ai-color-black;
            z-index: 9;

            &:nth-child(1) {
                left: calc(8% - 1px);
            }
            &:nth-child(2) {
                left: calc(50% - 1px);
            }
            &:nth-child(3) {
                right: calc(8% - 1px);
            }
        }
    }

    .c-result-range--label-min {
        position: absolute;
        font-size: 0.75rem;
        font-weight: 600;
        bottom: 0;
        left: 8%;
        transform: translateX(-50%);
    }

    .c-result-range--label-center {
        position: absolute;
        font-size: 0.75rem;
        font-weight: 600;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .c-result-range--label-max {
        position: absolute;
        font-size: 0.75rem;
        font-weight: 600;
        bottom: 0;
        right: 8%;
        transform: translateX(50%);
    }

    .c-result-range--marker {
        position: absolute;
        top: 4px;
        width: 24px;
        height: 12px;
        transform: translateX(-50%);

        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../../images/svg/Marker.svg');
    }
}

.c-result-range--dot {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.c-question--wrapper {
    padding-left: 64px;
    margin-bottom: 24px;
    position: relative;


    .c-question--title {
        padding-right: 24px;
        font-size: .825rem;
        line-height: 20px;
    }

    .c-question--info {
        position: absolute;
        right: 24px;
        top: 24px;
        display: block;
        width: 16px;
        height: 16px;
        z-index: 9;
    }

    .c-question--answers {
        button {
            text-align: left;
            justify-content: left;

            span {
                font-weight: 400;
                line-height: 16px;
            }

            &:disabled {
                pointer-events: none;
            span {
                position: relative;
                user-select: none;
                color: transparent;
                background-color: $di-ai-color-lightgray;
                border-radius: 8px;
            }
        }
        }
    }

    .c-question--counter {
        position: absolute;
        top: 16px;
        left: 0;
        width: 40px;
        height: 40px;
        border: 12px solid $di-ai-color-lightgray;
        background-color: $di-ai-color-white;
        border-radius: 50%;
        z-index: 9;
        line-height: 32px;
        text-align: center;
    }


    .c-question--line {
        display: block;
        position: absolute;
        top: -80px;
        bottom: 50px;
        left: 18px;
        width: 4px;
        background-color: $di-ai-color-lightgray;
        span {
            display: block;
            position: relative;
            z-index: 5;
            width: 100%;
            height: 100%;
            max-height: 0;
            background-color: $di-ai-color-green;
            // transition: max-height 1s linear;
            border-radius: 2px;
        }
    }

    &.disabled {
        .card {
            background-color: transparent;
        }
        .c-question--title {
            span {
                user-select: none;
                color: transparent;
                background-color: $di-ai-color-lightgray;
                border-radius: 8px;
                box-decoration-break: clone;
            }
        }
        .c-question--info {
            background-color: $di-ai-color-lightgray;
            pointer-events: none;
            border-radius: 50%;
            &:after {
                background-image: none;
            }
        }
    }

    &.check {
        .c-question--line {
            span {
                max-height: 100%;
            }
        }
        .c-question--counter {
            border: 4px solid $di-ai-color-green;
        }
    }

    &.current {
        .card {
            border: 2px solid $di-ai-color-blue;
            .card-body {
                padding: 23px;
            }
        }
        .c-question--counter {
            border: 4px solid $di-ai-color-blue;
        }
    }

    &:first-child {
        .c-question--line {
            top: 26px;
        }
    }

    &:last-child {
        .c-question--line {
            bottom: calc(100% - 26px);
        }
    }
}

.c-question--desc {
    padding-left: 23px;
    border-left: 1px solid $di-ai-color-lightgray;
}

.c-core--wrapper {



    .c-core--header {
        padding-top: 16px;
        padding-bottom: 64px;

        &.c-core--level-1 {
            background-color: $di-ai-color-darkgreen;
        }

        &.c-core--level-2 {
            background-color: $di-ai-color-yellow;
        }

        &.c-core--level-3 {
            background-color: $di-ai-color-orange;
        }

        &.c-core--level-4 {
            background-color: $di-ai-color-black;
            min-height: calc(100vh - #{$di-ai-navbar-height});
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
        }

        .c-core--title {
            align-self: center;
            margin: 0;
            font-weight: 400;

            strong, b {
                font-weight: 600;
            }
        }
    
        .c-core--image {
            min-height: 160px;
            // text-align: center;
            // align-self: center;
        }
    }
}

.c-core--card {

    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 2px;
    transition: background-color .7s ease;

    &.expanded {
        &:not(.alt) {
            background-color: #F4F4F5;
        }
        .c-core--card--header {
            a {
                &:after {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
            h4 {
                font-weight: 600;
            }
        }
    }

    &--header {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        h4 {
            margin: 0;
            flex-grow: 1;
            font-weight: 400;
        }

        img {
            width: 40px;
            height: 40px;
        }
        a {
            display: block;
            width: 32px;
            height: 32px;
            &:after {
                transition: transform .35s ease;
                width: 32px;
                height: 32px;
            }
        }
    }
    // &--desc {

    // }
}

.c-core--toggler {
    display: block;
    width: 32px;
    height: 32px;
    &:after {
        width: 32px;
        height: 32px;
    }
}

.u-cursor-pointer {
    cursor: pointer !important;
}

.di-ai-research-separator {
    height: 124px;
    width: 32px;

    @include media-breakpoint-down(md) {
        display: none;
      }
}

.di-ai-research-card {
    min-height: 124px;
    margin-bottom: 16px;

    &--item {
        display: flex;
        align-items: center;
        padding: 12px 16px;

        border: 1px solid #C7CACD;
        border-radius: 4px;
        margin-bottom: 8px;

        .di-ai-icon-sm {
            opacity: .35;
            // cursor: pointer;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            .di-ai-icon-sm {
                opacity: 1;
            }
        }

        // &--name {
            
        // }
        &--price {
            align-self: flex-end;
            color: $di-ai-color-blue;
            flex-wrap: nowrap;
        }
    }
}

.di-ai-recommendations-card {
    margin-bottom: 4px;
    .di-ai-recommendations-card--item {
        padding: 12px 0 12px 28px;
        font-size: 14px;
        &:after {
            left: 0;
        }
        // border-bottom: 1px solid rgba($di-ai-color-black, .05);


        // &:last-child {
        //     border-bottom: none;
        // }
    }
}

.di-ai-tooltip {
    max-width: 450px;
    white-space: pre-line;
}


.link {
    &, &-black, &-blue {
        cursor: pointer;
    }
}



.c-card-summary {



    .c-card-summary--header {
        padding: 24px;

        &.c-card-summary--level-1 {
            background-color: $di-ai-color-darkgreen;
        }

        &.c-card-summary--level-2 {
            background-color: $di-ai-color-yellow;
        }

        &.c-card-summary--level-3 {
            background-color: $di-ai-color-orange;
        }

        &.c-card-summary--level-4 {
            background-color: $di-ai-color-black;
        }

        .c-card-summary--title {
            // align-self: center;
            margin: 0;
            font-weight: 400;

            strong, b {
                font-weight: 600;
            }
        }
    
        .c-card-summary--image {
            min-height: 80px;
            max-height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            img {
                width: 48px;
                height: 48px;
                object-fit: contain;
            }
        }
    }
}


.fake-tab {
    height: 100%;
    border-bottom: 2px solid $di-ai-color-blue;
    padding: 24px 0;
    width: fit-content;
    user-select: none;
    span {
        cursor: pointer;
        font-weight: 600;
    }
}

.import-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    &:after {
        background-image: url(../../images/png/import.png);
    }
}


select.form-control {
    padding-right: 44px;
}

.no-left-border {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: none !important;
}

.no-right-border {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.card-bmi {
    // background-color: #F9F9F9;
    background-image: url(../../images/jpg/gettyimages-145839980.jpg), linear-gradient(to right, #FFF 0% , #F9F9F9 40%);
    background-size: 60%, 100%;
    background-position: right;
    background-repeat: no-repeat;
}

.card-pamietaj {
    // background-color: #F9F9F9;
    background-image: linear-gradient(90deg, #FFFFFF 0%, transparent 50%), url(../../images/png/AdobeStock_560736218.png);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
}

.flip-icon {
    &:after {
        transform: scaleY(-1);
        transform-origin: 50% 25%;
    }
}

.di-ai-interpretation-bkg {
    background-color: #D8E0E2;
    background-image: linear-gradient(90deg, #D8E0E2 0%, transparent 60%), url(../../images/png/AdobeStock_304453347.png);
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}


.di-ai-content-wrapper-alter--footer-end {
    background-color: $di-ai-color-black;
    background-image: linear-gradient(90deg, #1F2B39 0%, transparent 60%), url(../../images/png/AdobeStock_251688045.png);
    background-position: top left;
    background-size: cover;
    background-repeat: no-repeat;
}