.di-ai-navbar {
    background-color: $di-ai-color-white;
    min-height: $di-ai-navbar-height;
    border-bottom: 1px solid;
    border-color: $di-ai-color-gray;

    .di-ai-container {
        .di-ai-divider-v {
            display: block;
            width: 0;
            height: 32px;
            border-left: 1px solid;
            border-color: $di-ai-color-gray;
        }

        .di-ai-icon-menu {
            background-image: di-ai-get-icon('menu', $di-ai-color-black);
            display: inline-block;
            width: 24px;
            height: 24px;
        }
    }

    .logo {
        height: 36px;
    }
}


.di-ai-navbar-lite {
    min-height: $di-ai-navbar-height;
    // color: $di-ai-color-white;
    position: relative;
}